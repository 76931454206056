
          @import "src/assets/styles/_variables.scss";
          @import "src/assets/styles/_media.scss";
        
.icon {
  color: $color-main;
  text-decoration: none;
  cursor: pointer;
  margin: 20px;
}

.icon:hover {
  color: #ffc594;
  transition: 0.3s;
}

.icon_down {
  position: absolute;
  right: 10px;
  bottom: 10px;
  cursor: pointer;
}

.list_register {
  bottom: 20px;
  width: calc(50% - 287px);
  position: absolute;
  left: 10px;
  z-index: 500;
  @include notebook {
    position: relative;
    left: auto!important;
    bottom: auto;
    width: 100%;
  }
}

.list_register_wrap {
  margin-top: 10px;
  position: relative;
  background: $color-light;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 6px 6px -3px #272C331A, 0 10px 14px 1px #272C330A, 0 4px 18px 3px #272C3305;
  @include notebook {
    margin: 0 0 15px;
    width: calc(100% - 40px);
  }
}

.clearFinished {
  color: red;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.5s;
  &:hover {
    opacity: 1;
  }
}

.list {
  margin: 0;
  padding: 0;
  max-height: 150px;
  overflow: auto;
  list-style-position: inside;

  @include tablet {
    max-height: 220px;
  }
}

.result {
  border-top: 1px solid $color-main;
  padding: 5px 0;
}

.result_text {
  color: $color-main;
}

.caviar_wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.red_circle {
  display: inline-block;
  background: red;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
}

.caviar_text {
  font-size: 12px;
  line-height: 1;
}
